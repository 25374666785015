import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import DownLoadProgress from './downLoadProgress.vue';
import fileDownlow from '@/common/data-download';
let ImportComp = class ImportComp extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.fileList = {};
        this.successData = {};
        this.canImport = false;
        this.importStatus = '';
        // 导入进度
        this.percent = 0;
        // 是否导入完成
        this.isImportEnd = false;
        this.downloadDisplay = false;
        this.Progress = 1;
        this.showFile = false;
    }
    async downloadTempl() {
        this.Progress = 1;
        this.downloadDisplay = true;
        await this.tempApi(this.progressInfo).then(res => {
            // 获取后端返回的文件名
            const headers = res.orgResponse.headers || {};
            const disposition = headers['content-disposition'] || '';
            const unDecodefileName = disposition.split('filename=')[1] || '';
            const fileStream = res.orgResponse.data;
            const fileType = 'application/octet-stream';
            const fileName = decodeURIComponent(unDecodefileName);
            fileDownlow.downloadByPost(fileStream, fileType, fileName);
            this.Progress = 100;
            setTimeout(() => {
                this.downloadDisplay = false;
            }, 500);
        });
    }
    formatName(name) {
        if (name.length > 18) {
            const nameStart = name.substr(0, 7);
            const nameEnd = name.substr(name.length - 11, 7);
            return `${nameStart}...${nameEnd}.zip`;
        }
        return name;
    }
    progressInfo(e) {
        if (e.lengthComputable) {
            const progress = Math.floor((e.loaded / e.total) * 100);
            this.Progress = progress;
        }
    }
    handleChange(info) {
        const fileName = info.file.name;
        const isError = !/\.xlsx$/i.test(fileName);
        if (isError) {
            this.$message.error('文件格式不对');
            return;
        }
        this.showFile = Boolean(info.file);
        this.fileList = info.file;
        if (this.showFile) {
            this.canImport = true;
        }
    }
    getSuffix(fileName) {
        const index = fileName.lastIndexOf('.');
        const suffix = fileName.substring(index + 1);
        return suffix;
    }
    /**
     * 判断上传的文件是否zip文件
     */
    beforeUpload() {
        return false;
    }
    remove(file) {
        // console.log(file, 'dsd');
        this.fileList = {};
        this.showFile = false;
        this.canImport = false;
        this.$emit('change', false);
        this.$emit('remove', file.response);
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    async downErrorFile() {
        this.Progress = 1;
        this.downloadDisplay = true;
        await OrgManageService.downloadTemplate({ refId: this.successData.errorFileRefId }, this.progressInfo).then(res => {
            // 获取后端返回的文件名
            const headers = res.orgResponse.headers || {};
            const disposition = headers['content-disposition'] || '';
            const unDecodefileName = disposition.split('fileName=')[1] || '';
            const fileStream = res.orgResponse.data;
            const fileType = 'application/octet-stream';
            const fileName = decodeURIComponent(unDecodefileName);
            fileDownlow.downloadByPost(fileStream, fileType, fileName);
            this.Progress = 100;
            setTimeout(() => {
                this.downloadDisplay = false;
            }, 500);
        });
    }
    async confirm() {
        this.importStatus = 'importing';
        this.getBase64(this.fileList)
            .then(() => {
            this.uploadApi(this.fileList, this.params, (progress) => {
                this.percent = Math.round((progress.loaded / progress.total) * 100);
            })
                .then(res => {
                if (res.success) {
                    this.importStatus = 'importEnd';
                    this.successData = res.data;
                    this.isImportEnd = true;
                }
                else {
                    this.reset();
                }
            });
        })
            .catch(() => {
            this.reset();
            Vue.prototype.$errorcatcher.showMessagePopup({
                title: '文件不存在',
                message: ['File does not exist!', 'zip包不存在'],
                correctGuide: '请检查本地文件是否已经修改或删除',
                displayFooter: false,
            });
        });
    }
    async cancel() {
        this.$emit('input', false);
        this.reset();
    }
    finish() {
        this.$emit('finish');
        this.$emit('input', false);
        this.reset();
    }
    reset() {
        this.importStatus = '';
        this.isImportEnd = false;
        this.percent = 0;
        this.showFile = false;
        this.canImport = false;
        this.fileList = {};
    }
    onChange(v) {
        this.visible = v;
    }
};
__decorate([
    Prop()
], ImportComp.prototype, "value", void 0);
__decorate([
    Prop()
], ImportComp.prototype, "title", void 0);
__decorate([
    Prop({ default: () => { } })
], ImportComp.prototype, "params", void 0);
__decorate([
    Prop({ default: OrgManageService.uploadUser })
], ImportComp.prototype, "uploadApi", void 0);
__decorate([
    Prop({ default: OrgManageService.downloadUserTemplate })
], ImportComp.prototype, "tempApi", void 0);
__decorate([
    Watch('value', { immediate: true })
], ImportComp.prototype, "onChange", null);
ImportComp = __decorate([
    Component({
        name: 'ImportComp',
        components: {
            DownLoadProgress,
        },
    })
], ImportComp);
export default ImportComp;
