import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let DownLoadProgress = class DownLoadProgress extends Vue {
    created() { }
};
__decorate([
    Prop()
], DownLoadProgress.prototype, "downloadDisplay", void 0);
__decorate([
    Prop({ default: '正在下载...请稍后' })
], DownLoadProgress.prototype, "title", void 0);
__decorate([
    Prop({ default: 1 })
], DownLoadProgress.prototype, "downloadProgress", void 0);
DownLoadProgress = __decorate([
    Component({
        name: 'DownLoadProgress',
    })
], DownLoadProgress);
export default DownLoadProgress;
